import React from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import {Helmet} from "react-helmet"; 

import { Navbar } from "../../components/Navbar";
import {
  Hero,
  WhoAreCaregivers,
  Jumbotron,
  Platform,
  PlatformFor,
  WhyMCL,
  SectionBanner,
} from "../../components/HomeComponents";
import Footer from "../../components/Footer";
import HomeHero from "../../assets/images/home-hero.png";

//import { Link } from "react-router-dom";

export const Home = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const modal = searchParams.get('modal');
  const contact = modal === 'contact' ? true : false;
  const schedule = modal === 'schedule' ? true : false;

  // console.log('!@!@:', modal, contact, schedule);

  return (
    <main>
      <Helmet>
          <meta charSet="utf-8" />
          <title>My CareLinq - Consumer</title>
          {/* <meta name="description" content="My CareLinq is a collaborative care, support, and resource management platform. We also offer affordable services to help get urgent tasks done." /> */}
          <meta name="description" content="For Family Caregivers: Create a family care plan for your loved one and a support plan for yourself. For Yourself: Create your plan, make your care wishes known, leave instructions for your care team." />
          <link rel="canonical" href="https://mycarelinq.com/product-consumer" />
      </Helmet>
      <Navbar signin={true} navigate={navigate} />
      <section
        className="bg-cover bg-no-repeat bg-center overflow-hidden relative"
        style={{
          minHeight: "500px",
          height: "flex",
          backgroundImage: `url(${HomeHero})`,
        }}
        id="home"
      >
        <Hero />
      </section>
      <section className="overflow-hidden">
        <SectionBanner message="My CareLinq is a collaborative care, support, and resource management platform. We also offer affordable services to help get urgent tasks done. Sign up for free." />
      </section>
      <section className="overflow-hidden">
        <WhoAreCaregivers />
      </section>
      <section className="overflow-hidden">
        <SectionBanner message="We are on a mission to change the family caregiving model through personalized support and curated resources that you control from an online dashboard." />
      </section>
      {/* <section id="platform-overview" className="overflow-hidden">
        <Platform
        getStartedFunc={() => {
          navigate("/survey");
        }}
        />
      </section> */}
      {/* <section id="platform" className="overflow-hidden">
        <Platform />
      </section> */}
      <section id="platform-built4u" className="overflow-hidden">
        <PlatformFor showContact={contact}/>
      </section>
      <section className="overflow-hidden">
        <SectionBanner message="Create an account to get started on a better path to caregiving." />
      </section>
      <section id="testimonials" className="overflow-hidden">
        <Jumbotron />
      </section>
      {/* <section className="overflow-hidden">
        <SectionBanner message="To those of you who need care, and those that give it, we hear your struggles and we are here to improve the caregiving and planning experience." />
      </section>
      <section id="platform-whyMCL" className="overflow-hidden">
        <WhyMCL
          getStartedFunc={() => {
            navigate("/survey");
          }}
        />
      </section> */}
      <Footer showSchedule={schedule}/>
    </main>
  );
};
