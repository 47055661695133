import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import useMclAuth from "../../hooks/useMclAuth";
import useAuth from "../../hooks/useAuth";
import { Url } from "../../constants/Global";
import { useNavigate } from "react-router";
import { mclOpen } from "../../api/Mcl";

import { Col, Row, Space, Empty, Avatar, Card, Button, Spin } from "antd";
import { CheckCircleTwoTone, CheckCircleFilled, 
  UserOutlined, VideoCameraOutlined,
  CalendarOutlined, GlobalOutlined
} from "@ant-design/icons";

import { Navbar } from "../../components/Navbar.js";
import Footer from "../../components/Footer.js";

import Min from "../../assets/images/team/MinPomper_CEO.png";


import { AUTH_KEY } from "../../constants/Global";

function SuccessPayment() {
  const mclAuth = useMclAuth();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const { auth, setAuth } = useAuth();
  const { CHECKOUT_SESSION_ID } = useParams();

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      let authState = localStorage.getItem(AUTH_KEY);
      if (authState) {
        const user = JSON.parse(authState);
        setAuth({
          id: user?.id,
          email: user?.email,
          password: "",
          care: user?.care,
          roles: user?.roles,
          // accessToken: localStorage.getItem("accessToken"),
          accessToken: user?.accessToken,
        });
      }

      if (auth.accessToken) {
        setMessage("Order placed! You will receive an email confirmation.");

        mclAuth
          .get(Url.PAYMENT.concat(`/successPayment/${CHECKOUT_SESSION_ID}`))
          .then((res) => {
            console.log("response: ", res.data);
            //localStorage.removeItem("accessToken");
            //localStorage.removeItem("tempEmail");
          })
          .catch((error) => {
            console.log(error.message);
          });

        setTimeout(function () {
          // navigate("/site/login");
          navigate("/caregiver/dashboard");
        }, 3000);
      } else {
        setMessage("Missing login token.");
        //localStorage.removeItem("accessToken");
        //localStorage.removeItem("tempEmail");
      }
    } else {
      if (localStorage.getItem("tempEmail")) {
        setMessage("Order placed! You will receive an email confirmation.");

        mclOpen
          .get(Url.PAYMENT.concat(`/successPaymentTemp/${CHECKOUT_SESSION_ID}`))
          .then((res) => {
            //localStorage.removeItem("accessToken");
            //localStorage.removeItem("tempEmail");
            console.log(res);
            setTimeout(function () {
              navigate("/survey/1/" + res.data._id);
            }, 3000);
          })
          .catch((error) => {
            console.log(error.message);
          });
      } else {
        setMessage("Missing email address.");
        //localStorage.removeItem("accessToken");
        //localStorage.removeItem("tempEmail");
      }
    }
  }, [auth.id]);

  return (
    <main>
      <p className="w-fill p-8 text-center text-lg">{message}</p>
    </main>
  );
}

function SuccessBuyNow() {
  const mclAuth = useMclAuth();
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const [data, setData] = useState({});
  const { auth, setAuth } = useAuth();
  const { CHECKOUT_SESSION_ID } = useParams();

  // useEffect(() => {

  //   if (localStorage.getItem("accessToken")) {
  //     let authState = localStorage.getItem(AUTH_KEY);
  //     if (authState) {
  //       const user = JSON.parse(authState);
  //       setAuth({
  //         id: user?.id,
  //         email: user?.email,
  //         password: "",
  //         care: user?.care,
  //         roles: user?.roles,
  //         // accessToken: localStorage.getItem("accessToken"),
  //         accessToken: user?.accessToken,
  //       });
  //     }

  //     if (auth.accessToken) {
  //       setMessage("Order placed! You will receive an email confirmation.");

  //       mclAuth
  //         .get(Url.PAYMENT.concat(`/successPaymentBuyNow/${CHECKOUT_SESSION_ID}`))
  //         .then((res) => {
  //           console.log("response: ", res.data);
  //           //localStorage.removeItem("accessToken");
  //           //localStorage.removeItem("tempEmail");
  //         })
  //         .catch((error) => {
  //           console.log(error.message);
  //         });

  //       setTimeout(function () {
  //         // navigate("/site/login");
  //         navigate("/caregiver/dashboard");
  //       }, 3000);
  //     } else {
  //       setMessage("Missing login token.");
  //       //localStorage.removeItem("accessToken");
  //       //localStorage.removeItem("tempEmail");
  //     }
  //   } else {
  //     if (localStorage.getItem("tempEmail")) {
  //       setMessage("Order placed! You will receive an email confirmation.");

  //       mclOpen
  //         .get(Url.PAYMENT.concat(`/successPaymentTemp/${CHECKOUT_SESSION_ID}`))
  //         .then((res) => {
  //           //localStorage.removeItem("accessToken");
  //           //localStorage.removeItem("tempEmail");
  //           console.log(res);
  //           setTimeout(function () {
  //             navigate("/survey/1/" + res.data._id);
  //           }, 3000);
  //         })
  //         .catch((error) => {
  //           console.log(error.message);
  //         });
  //     } else {
  //       setMessage("Missing email address.");
  //       //localStorage.removeItem("accessToken");
  //       //localStorage.removeItem("tempEmail");
  //     }
  //   }
  // }, [auth.id]);


  useEffect(() => {
    mclAuth
      .get(Url.PAYMENT.concat(`/successPaymentBuyNow/${CHECKOUT_SESSION_ID}`))
      .then((res) => {
        console.log("response: ", res.data);
        // setMessage(JSON.stringify(res.data));
        setData(res.data);
        //localStorage.removeItem("accessToken");
        //localStorage.removeItem("tempEmail");
      })
      .catch((error) => {
        setMessage(error.message);
        console.log(error.message);
      });

  }, [CHECKOUT_SESSION_ID])

  return (
    <main>
      <Navbar signin={true} navigate={navigate} />
      {message ? 
      <p className="w-fill p-8 text-center text-lg">{message}</p>
      : 
      <div>
      <Row className="px-3 mt-20">
        <Col md={24} className="text-center">
          <Avatar
            className="cursor-pointer"
            src={Min}
            size={80}
            // onClick={() => {
            //   avatarOnClick(value);
            // }}
          />
        </Col>
      </Row>
      <Row className="px-3">
        <Col md={24} className="text-center">   
          <h2><CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: '1.25em'}}/>
          {/* <CheckCircleTwoTone style={{ fontSize: '1.5em', color: '#38761d' }} /> */}
          &nbsp;
          Thank you for availing of our&nbsp;{data.description}</h2>
        </Col>
      </Row>
      <Row className="px-3 mt-10">
        <Col md={24} className="text-center">
          <h3 style={{ fontSize: '1.25em'}}>
            <span>
              Please schedule a
              <Button
              className={
                // "mx-auto mb-3 border-0 rounded-lg bg-[#44cff2] text-white text-xl h-fit w-3/4"
                "mx-auto border-0 text-highlight-cyan text-bold text-xl"
              }
              // onClick={startButtonClick}
                onClick={(event) => {window.open("https://calendly.com/mycarelinq", "_blank")}}
              >
                talk 
              </Button>
              with Min for the implementation details of the service.
            </span>
          </h3>
        </Col>
      </Row>
      <Row className="px-3 mt-10 mb-20">
        <Col md={24} className="text-center">
          <h3>
            Additional details have been sent to your email address:&nbsp;{data.email}<br/><br/>
            ref no: {data.session}
          </h3>
        </Col>
      </Row>
      </div>
      }

      <Footer style={{ textAlign: "center" }}></Footer>
    </main>
  );
}

function SuccessRegister() {
  const mclAuth = useMclAuth();
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({description: 'just desc', email:'just email'});
  const { auth, setAuth } = useAuth();
  const { REGISTER_ID, CHECKOUT_SESSION_ID } = useParams();

  // useEffect(() => {

  //   if (localStorage.getItem("accessToken")) {
  //     let authState = localStorage.getItem(AUTH_KEY);
  //     if (authState) {
  //       const user = JSON.parse(authState);
  //       setAuth({
  //         id: user?.id,
  //         email: user?.email,
  //         password: "",
  //         care: user?.care,
  //         roles: user?.roles,
  //         // accessToken: localStorage.getItem("accessToken"),
  //         accessToken: user?.accessToken,
  //       });
  //     }

  //     if (auth.accessToken) {
  //       setMessage("Order placed! You will receive an email confirmation.");

  //       mclAuth
  //         .get(Url.PAYMENT.concat(`/successPaymentBuyNow/${CHECKOUT_SESSION_ID}`))
  //         .then((res) => {
  //           console.log("response: ", res.data);
  //           //localStorage.removeItem("accessToken");
  //           //localStorage.removeItem("tempEmail");
  //         })
  //         .catch((error) => {
  //           console.log(error.message);
  //         });

  //       setTimeout(function () {
  //         // navigate("/site/login");
  //         navigate("/caregiver/dashboard");
  //       }, 3000);
  //     } else {
  //       setMessage("Missing login token.");
  //       //localStorage.removeItem("accessToken");
  //       //localStorage.removeItem("tempEmail");
  //     }
  //   } else {
  //     if (localStorage.getItem("tempEmail")) {
  //       setMessage("Order placed! You will receive an email confirmation.");

  //       mclOpen
  //         .get(Url.PAYMENT.concat(`/successPaymentTemp/${CHECKOUT_SESSION_ID}`))
  //         .then((res) => {
  //           //localStorage.removeItem("accessToken");
  //           //localStorage.removeItem("tempEmail");
  //           console.log(res);
  //           setTimeout(function () {
  //             navigate("/survey/1/" + res.data._id);
  //           }, 3000);
  //         })
  //         .catch((error) => {
  //           console.log(error.message);
  //         });
  //     } else {
  //       setMessage("Missing email address.");
  //       //localStorage.removeItem("accessToken");
  //       //localStorage.removeItem("tempEmail");
  //     }
  //   }
  // }, [auth.id]);



  useEffect(() => {
    // console.log('successRegister!!');
    mclAuth
      .get(Url.PAYMENT.concat(`/successPaymentRegister/${REGISTER_ID}/${CHECKOUT_SESSION_ID}`))
      .then((res) => {
        console.log("response: ", res.data);
        // setMessage(JSON.stringify(res.data));
        setData(res.data);
        // localStorage.removeItem("accessToken");
        //localStorage.removeItem("tempEmail");
        setIsLoading(false);
      })
      .catch((error) => {
        setMessage(error.message);
        setIsLoading(false);
        console.log(error.message);
      });

  }, [CHECKOUT_SESSION_ID])

  return (
    <main>
      <Navbar signin={true} navigate={navigate} />
      <Spin spinning={isLoading}>
      {message ? 
      <p className="w-fill p-8 text-center text-lg">{message}</p>
      : 
      <div>
      <Row className="px-3 mt-20">
        <Col md={24} className="text-center">
          <Avatar
            className="cursor-pointer"
            src={Min}
            size={80}
            // onClick={() => {
            //   avatarOnClick(value);
            // }}
          />
        </Col>
      </Row>
      <Row className="px-3">
        <Col md={24} className="text-center">   
          <h2><CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: '1.25em'}}/>
          &nbsp;
          {/* <CheckCircleTwoTone style={{ fontSize: '1.5em', color: '#38761d' }} /> 
          Thank you for availing of our&nbsp;{data.description}
          */}
          Thank you for your purchase
          </h2>
        </Col>
      </Row>
      <Row className="px-3 mt-10">
        <Col md={24} className="text-center">
          <h3 style={{ fontSize: '1.25em'}}>
            <span>
              Please check your email for further instructions.
            </span>
          </h3>
        </Col>
      </Row>
      <Row className="px-3 mt-10 mb-20">
        <Col md={24} className="text-center">
          <h3>
            Additional details have been sent to your email address:&nbsp;{data.email}<br/><br/>
            ref no: {data.session}
          </h3>
        </Col>
      </Row>
      </div>
      }
      </Spin>
      <Footer style={{ textAlign: "center" }}></Footer>
    </main>
  );
}

export {SuccessPayment, SuccessBuyNow, SuccessRegister};
